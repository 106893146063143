/* eslint-disable react/jsx-props-no-spreading */
import { SessionProvider } from 'next-auth/react'
import { StrictMode } from 'react'
import { SWRConfig } from 'swr'

import Layout from '~/components/Layout'
import { LocalUserContext } from '~/context/useLocalUserContext.tsx'
import axios from '~/services/api'

import type { PageContext } from './types.ts'
import { PageContextProvider } from './usePageContext.tsx'

export const PageShell = ({ pageContext }: { pageContext: PageContext }) => {
  if (pageContext.session.token) {
    axios.defaults.headers.Authorization = pageContext.session.token
  }
  const { Page, pageProps, session, csrfToken, callbackUrl } = pageContext

  return (
    <StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <SessionProvider session={pageContext.session}>
          <LocalUserContext>
            <div className="h-full" suppressHydrationWarning>
              <SWRConfig
                value={{
                  fetcher: ([url, params]) =>
                    axios
                      .get(`${url}`, {
                        params,
                      })
                      .then((res) => res.data),
                }}>
                <Layout isLoggedIn={!!pageContext.session.token}>
                  <Page
                    {...pageProps}
                    session={session}
                    csrfToken={csrfToken}
                    callbackUrl={callbackUrl}
                  />
                </Layout>
              </SWRConfig>
            </div>
          </LocalUserContext>
        </SessionProvider>
      </PageContextProvider>
    </StrictMode>
  )
}
