import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  BanknotesIcon,
  Bars3CenterLeftIcon,
  CalendarIcon,
  ChevronDownIcon,
  CircleStackIcon,
  CogIcon,
  CurrencyPoundIcon,
  EnvelopeIcon,
  HomeIcon,
  ReceiptRefundIcon,
  Square2StackIcon,
} from '@heroicons/react/20/solid'
import { ArrowsRightLeftIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { getWeek } from 'date-fns'
import { signOut, useSession } from 'next-auth/react'
import { Fragment, ReactElement, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { navigate } from 'vike/client/router'

import logo from '~/assets/logo.webp'
import NotificationCenter from '~/components/NotificationCenter'
import ProfileSwitcher from '~/components/ProfileSwitcher'
import UserRoles from '~/constants/user-roles.ts'
import { useLocalUserContext } from '~/context/useLocalUserContext.tsx'
import { usePageContext } from '~/renderer/usePageContext.tsx'

type LayoutProps = {
  children: ReactElement
  isLoggedIn: boolean
}

const navigation = [
  {
    name: 'Dashboard',
    href: '/',
    icon: HomeIcon,
    roles: [UserRoles.TUTOR, UserRoles.ADMINISTRATOR, UserRoles.MODERATOR],
    isStudentPage: true,
  },
  {
    name: 'Account',
    href: '/',
    icon: HomeIcon,
    roles: [UserRoles.GUARDIAN],
  },
  {
    name: 'Payments',
    href: '/payments',
    icon: CurrencyPoundIcon,
    roles: [UserRoles.GUARDIAN],
  },
  {
    name: 'Calendar',
    href: '/calendar',
    icon: CalendarIcon,
    roles: [UserRoles.TUTOR],
  },
  {
    name: 'Courses',
    href: '/courses',
    icon: Square2StackIcon,
    roles: [UserRoles.TUTOR, UserRoles.ADMINISTRATOR, UserRoles.MODERATOR],
  },
  {
    name: 'Manage Lessons',
    href: '/manage-courses',
    icon: Square2StackIcon,
    roles: [UserRoles.GUARDIAN],
    isStudentPage: true,
  },
  {
    name: 'Refunds',
    href: '/refunds',
    icon: ReceiptRefundIcon,
    roles: [UserRoles.ADMINISTRATOR, UserRoles.MODERATOR],
  },
  {
    name: 'Lessons',
    href: '/lessons',
    icon: Square2StackIcon,
    roles: [UserRoles.ADMINISTRATOR, UserRoles.MODERATOR],
  },
  {
    name: 'Job Postings',
    href: '/jobs',
    icon: Square2StackIcon,
    roles: [UserRoles.TUTOR, UserRoles.ADMINISTRATOR, UserRoles.MODERATOR],
  },
  {
    name: 'Finances',
    href: '/finances',
    icon: BanknotesIcon,
    roles: [UserRoles.TUTOR],
  },
  {
    name: 'Messages',
    href: '/messages',
    icon: EnvelopeIcon,
    isStudentPage: true,
    roles: [UserRoles.TUTOR, UserRoles.GUARDIAN],
  },
  {
    name: 'Action & Resources',
    href: '/actions',
    icon: CircleStackIcon,
    roles: [],
  },
  {
    name: 'Users',
    href: '/users',
    icon: UsersIcon,
    roles: [UserRoles.ADMINISTRATOR, UserRoles.MODERATOR],
  },
  {
    name: 'Settings',
    href: '/settings',
    icon: CogIcon,
    roles: [UserRoles.ADMINISTRATOR, UserRoles.MODERATOR],
  },
]

const userNavigation = [{ name: 'My Profile', href: '/profile' }]

const Layout = ({ children, isLoggedIn }: LayoutProps) => {
  const { data } = useSession()
  const pageContext = usePageContext()
  const { setLocalUser } = useLocalUserContext()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [showProfileSwitcher, setShowProfileSwitcher] = useState(false)
  const { localUser } = useLocalUserContext()

  if (!isLoggedIn) {
    return children
  }

  const isCurrentPage = (item: string) => {
    if (item === '/') {
      return pageContext.urlPathname === item
    }
    return pageContext.urlPathname.includes(item)
  }

  const handleSetLocalUser = async (userIndex?: number) => {
    setLocalUser(userIndex)
    await navigate('/')
  }

  const selectedUser = localUser || data?.user

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col flex-shrink-0 px-4">
                  <div className="mb-3">
                    <img
                      className="block h-9 w-auto lg:hidden"
                      src={logo}
                      alt="Enlighten Learning"
                    />
                    <img
                      className="hidden h-12 w-auto lg:block"
                      src={logo}
                      alt="Enlighten Learning"
                    />
                  </div>
                  <p className="font-bold">Week {getWeek(new Date())}</p>
                </div>
                <nav
                  className="mt-5 h-full flex-shrink-0 divide-y divide-white overflow-y-auto"
                  aria-label="Sidebar">
                  <div className="space-y-1 px-2">
                    {navigation
                      .filter((nav) =>
                        localUser
                          ? nav.isStudentPage
                          : !nav.roles || nav.roles.includes(data!.user.role)
                      )
                      .map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            isCurrentPage(item.href)
                              ? 'bg-secondary text-white'
                              : 'hover:text-white hover:bg-secondary',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                          aria-current={isCurrentPage(item.href) ? 'page' : undefined}>
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col z-10">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-white border-r-gray-200 border-r pt-5 pb-4">
          <div className="flex flex-col flex-shrink-0 px-4">
            <div className="mb-3">
              <img
                className="block h-8 w-auto lg:hidden"
                src={logo}
                alt="Enlighten Learning"
              />
              <img
                className="hidden h-12 w-auto lg:block"
                src={logo}
                alt="Enlighten Learning"
              />
            </div>
            <p className="font-bold">Week {getWeek(new Date())}</p>
          </div>
          <nav
            className="mt-5 flex flex-1 flex-col divide-y divide-gray-200 overflow-y-auto"
            aria-label="Sidebar">
            <div className="space-y-1 px-2">
              {navigation
                .filter((nav) =>
                  localUser
                    ? nav.isStudentPage
                    : !nav.roles || nav.roles.includes(data!.user.role)
                )
                .map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      isCurrentPage(item.href)
                        ? 'bg-secondary text-white'
                        : 'hover:text-white hover:bg-secondary',
                      'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                    )}
                    aria-current={isCurrentPage(item.href) ? 'page' : undefined}>
                    <item.icon
                      className="mr-4 h-6 w-6 flex-shrink-0"
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
            </div>
          </nav>
        </div>
      </div>
      <div className="h-full flex flex-col">
        <div className="flex flex-col lg:pl-64">
          <div className="flex h-16 flex-shrink-0 border-b border-gray-200 lg:border-none bg-gray-50">
            <button
              type="button"
              className="bg-gray-50 border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary lg:hidden"
              onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Search bar */}
            <div className="flex flex-1 justify-end px-4 sm:px-6 lg:mx-auto lg:max-w-8xl lg:px-8">
              <div className="ml-4 flex items-center md:ml-6">
                {data?.user.role === UserRoles.GUARDIAN && (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm text-white me-5"
                    onClick={() => setShowProfileSwitcher(true)}>
                    <span>Switch user</span>
                    <ArrowsRightLeftIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                )}
                <NotificationCenter />

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3 z-30">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                      <img
                        className="h-8 w-8 rounded-full"
                        src={selectedUser!.avatar}
                        alt=""
                      />
                      <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                        <span className="sr-only">Open user menu for </span>
                        {selectedUser!.name}
                      </span>
                      <ChevronDownIcon
                        className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {!localUser &&
                        userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {() => (
                              <a
                                href={item.href}
                                className={classNames(
                                  isCurrentPage(item.href) ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}>
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      <Menu.Item key="signOut">
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => signOut()}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block w-full text-left px-4 py-2 text-sm text-gray-700'
                            )}>
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <main className="flex flex-1 flex-col lg:pl-64 bg-gray-50">
          <div>
            <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-8xl lg:px-8">
              <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                <div className="min-w-0 flex-1">{children}</div>
              </div>
            </div>
          </div>
          <ProfileSwitcher
            setLocalUser={handleSetLocalUser}
            user={data!.user}
            isOpen={showProfileSwitcher}
            setShowProfileSwitcher={setShowProfileSwitcher}
          />
        </main>
      </div>
      <ToastContainer />
    </>
  )
}

export default Layout
