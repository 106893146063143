import useSWR from 'swr'

import api from '~/services/api.ts'
import Notification from '~/types/entities/notification'

const NotificationService = {
  useGetAll() {
    const { data, error, isLoading, isValidating, mutate } = useSWR<Notification[]>([
      '/notifications',
    ])

    return {
      data,
      isLoading,
      isValidating,
      isError: error,
      mutate,
    }
  },
  markAllAsSeen() {
    return api.put(`/notifications`)
  },
}

export default NotificationService
