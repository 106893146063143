import './index.css'
import 'react-toastify/dist/ReactToastify.css'

import { createRoot, hydrateRoot, Root } from 'react-dom/client'

import { PageShell } from './PageShell.tsx'
import type { PageContext } from './types'

export const clientRouting = true
export const hydrationCanBeAborted = true

let root: Root

export async function render(pageContext: PageContext) {
  const page = <PageShell pageContext={pageContext} />

  const container = document.getElementById('react-container')!
  // SPA
  if (container?.innerHTML === '' || !pageContext.isHydration) {
    document.title = `${pageContext?.documentProps?.title} | Enlighten Learning`
    if (!root) {
      root = createRoot(container)
    }
    root.render(page)
    // SSR
  } else {
    root = hydrateRoot(container, page)
  }
}
