import { EyeIcon } from '@heroicons/react/20/solid'
import { BellIcon } from '@heroicons/react/24/solid'
import { format, formatDistanceToNow } from 'date-fns'
import { useEffect, useRef, useState } from 'react'

import dateFormat from '~/constants/date-format.ts'
import NotificationService from '~/services/notification-service.ts'

const NotificationCenter = () => {
  const [showNotifications, setShowNotifications] = useState<boolean>(false)

  const { data, mutate } = NotificationService.useGetAll()

  const notificationRef = useRef<HTMLDivElement>(null)

  const hasNewNotifications = data?.some((notification) => !notification.seen)

  const newNotificationsCount = data?.filter((notification) => !notification.seen).length

  const toggleNotifications = async () => {
    setShowNotifications(!showNotifications)
    if (!showNotifications && hasNewNotifications) {
      await NotificationService.markAllAsSeen()
      await mutate(data?.map((notification) => ({ ...notification, seen: true })))
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setShowNotifications(false)
      }
    }

    if (showNotifications) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showNotifications])

  const formatNotificationDate = (date: Date) => {
    const now = new Date()
    const differenceInDays = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    )

    if (differenceInDays < 7) {
      return `${formatDistanceToNow(date, { addSuffix: true })}`
    }
    return format(date, dateFormat)
  }

  return (
    <div className="relative" ref={notificationRef}>
      <button
        type="button"
        onClick={toggleNotifications}
        className={`flex items-center rounded-full p-1 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2 ${
          hasNewNotifications
            ? 'text-secondary hover:text-secondary'
            : 'text-gray-400 hover:text-gray-500'
        }`}>
        <span className="sr-only">View notifications</span>
        {hasNewNotifications && (
          <p className="badge badge-secondary">{newNotificationsCount}</p>
        )}
        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      {showNotifications && (
        <div className="absolute right-0 mt-2 w-80 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="p-4 border-b font-semibold text-gray-700">Notifications</div>
          <div className="p-2 max-h-72 overflow-y-auto">
            {data?.length === 0 ? (
              <div className="text-gray-500 text-center">No new notifications</div>
            ) : (
              data?.map((notification) => (
                <div
                  key={notification.id}
                  className={`flex justify-between items-center p-2 border-b last:border-b-0 cursor-pointer ${
                    notification.seen ? 'bg-gray-50' : 'bg-yellow-50'
                  } hover:bg-gray-100`}>
                  <div>
                    <h3
                      className={`font-semibold text-sm ${
                        notification.seen ? 'text-gray-700' : 'text-yellow-600 font-bold'
                      }`}>
                      {notification.title}
                    </h3>
                    <p className="text-sm text-gray-500">{notification.message}</p>
                    <p className="text-xs text-gray-400">
                      {formatNotificationDate(new Date(notification.createdAt))}
                    </p>
                  </div>
                  {notification.relatedRoute && (
                    <a
                      onClick={toggleNotifications}
                      href={notification.relatedRoute}
                      className="btn btn-primary btn-sm">
                      <EyeIcon className="h-4 w-4" />
                    </a>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default NotificationCenter
